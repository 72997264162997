<template>
  <footer class="kiwi-footer">
    <section class="kiwi-footer-top py-8">
      <v-container>
        <v-row>
          <v-col cols="12" sm="6" md="3">
            <article class="d-flex flex-column">
              <img src="@/assets/logo.svg" style="width: 151px;" alt="">
              <p class="kiwi-fs-16 mt-6">An exceptional practice platform for SAT & PTE featuring curated mock tests with AI-powered scoring.</p>
            </article>
          </v-col>
          <v-col cols="12" md="3" class="d-none d-md-block"></v-col>
          <v-col cols="12" sm="6" md="2">
            <article class="d-flex flex-column">
              <h6 class="kiwi-fs-15 font-weight-semi-bold text-uppercase mb-3" style="">Company</h6>
              <v-list
                :lines="false"
                density="compact"
                :ripple="false"
                variant="flat"
                class="justify-md-center d-flex flex-column"
              >
                <v-list-item
                  v-for="(item, i) in company"
                  :key="i"
                  :value="item"
                  :to="item.link"
                  class="px-0"
                >
                  <v-list-item-title v-text="item.title" class="kiwi-fs-14"></v-list-item-title>
                </v-list-item>
              </v-list>
            </article>
          </v-col>
          <v-col cols="12" sm="6" md="2" class="overflow-hidden">
            <article class="d-flex flex-column">
              <h6 class="kiwi-fs-15 font-weight-semi-bold text-uppercase mb-3" style="">Resources</h6>
              <v-list
                :lines="false"
                density="compact"
                :ripple="false"
                variant="flat"
                class="justify-md-center d-flex flex-column"
                style=""
              >
                <v-list-item
                  v-for="(item, i) in support"
                  :key="i"
                  :value="item"
                  :to="item.link"
                  class="px-0"
                >
                  <v-list-item-title v-text="item.title" class="kiwi-fs-14"></v-list-item-title>
                </v-list-item>
              </v-list>
            </article>
          </v-col>
          <v-col cols="12" sm="6" md="2" class="overflow-hidden">
            <article class="d-flex flex-column">
              <h6 class="kiwi-fs-15 font-weight-semi-bold text-uppercase mb-3" style="">Join US</h6>
              <div class="d-flex justify-md-start">
                <a class="kiwi-text-black social-icon-link text-decoration-none" target="_blank" href="https://www.facebook.com/profile.php?id=61560503900717&mibextid=ZbWKwL"><v-icon class="mdi mdi-facebook"></v-icon></a>
                <a class="kiwi-text-black social-icon-link text-decoration-none ml-4" target="_blank" href="https://www.instagram.com/kiwiread.com_/"><v-icon class="mdi mdi-instagram"></v-icon></a>
              </div>
            </article>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <v-divider></v-divider>
    <section class="kiwi-footer-middle">
      <v-container>
        <v-row>
          
          <v-col cols="12" sm="6" md="4" lg="3">
            <article class="d-flex flex-column">
              <img src="/assets/Nepal.png" alt="Nepal FLag" class="mb-3" style="width: 28px;">
              <div class="d-flex flex-column align-start justify-start py-2">
                <div class="kiwi-fs-15 mb-4">
                  <b class="font-weight-semi-bold kiwi-fs-15">Nepal Office(HQ)</b> 
                  <div
                    class="d-flex flex-row flex-wrap align-start text-decoration-none kiwi-text-black mt-3" 
                  >
                    <v-icon class="mdi mdi-account mr-2 kiwi-text-primary"></v-icon>
                    <div style="flex: 1;"><p class="kiwi-fs-14">Suruchi Khadga (Representative)</p></div>
                  </div>
                  
                </div>
                <template v-for="(item, i) in contact" :key="i">
                  <template v-if="item.children">
                  <div
                    class="d-flex flex-row flex-wrap align-start text-decoration-none kiwi-text-black" 
                  >
                    <v-icon class="mdi mr-2 kiwi-text-primary" :class="item.icon"></v-icon>
                    <div style="flex: 1;">
                      <template
                        v-for="(ch, j) in item.children"
                        :key="j"
                      >
                        <a
                          :href="ch.link" 
                          :class="j > 0 ? 'ml-2' : ''"
                          class="flex-grow-1 w-100 text-decoration-none kiwi-text-black" 
                        >
                          <span>{{ch.title}}</span>
                        </a>
                        <span v-if="j === 0">,</span>
                      </template>

                    </div>
                  </div>
                  </template>
                  <a 
                    :href="item.link" 
                    class="d-flex flex-row flex-wrap align-center text-decoration-none kiwi-text-black" 
                    :class="i > 0 ? 'mt-4' : ''"
                    v-else-if="!item.children"
                  >
                    <v-icon class="mdi mr-2 kiwi-text-primary" :class="item.icon"></v-icon>
                    <span>{{item.title}}</span>
                  </a>
                </template>
                <div
                    class="d-flex flex-row flex-wrap align-start text-decoration-none kiwi-text-black mt-3" 
                  >
                    <v-icon class="mdi mdi-map-marker-outline mr-2 kiwi-text-primary"></v-icon>
                    <div style="flex: 1;"><p>Kupondole, Lalitpur, Nepal <br />(Opp. to Hotel Himalaya)</p></div>
                  </div>
              </div>
            </article>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <article class="d-flex flex-column">
              <img src="/assets/Bangladesh.png" alt="Bangladesh FLag" class="mb-3" style="width: 28px;">
              <div class="d-flex flex-column align-start justify-start py-2">
                <div class="kiwi-fs-15 mb-4">
                  <b class="font-weight-semi-bold kiwi-fs-15">Bangladesh Office</b> 
                  <div
                    class="d-flex flex-row flex-wrap align-start text-decoration-none kiwi-text-black mt-3" 
                  >
                    <v-icon class="mdi mdi-account mr-2 kiwi-text-primary"></v-icon>
                    <div style="flex: 1;"><p class="kiwi-fs-14">Mrinmoy Chakraborty (Representative)</p></div>
                </div>
                </div>
                <template v-for="(item, i) in contactBangladesh" :key="i">
                  <template v-if="item.children">
                  <div
                    class="d-flex flex-row flex-wrap align-start text-decoration-none kiwi-text-black" 
                  >
                    <v-icon class="mdi mr-2 kiwi-text-primary" :class="item.icon"></v-icon>
                    <div style="flex: 1;">
                      <template
                        v-for="(ch, j) in item.children"
                        :key="j"
                      >
                        <a
                          :href="ch.link" 
                          :class="j > 0 ? 'ml-2' : ''"
                          class="flex-grow-1 w-100 text-decoration-none kiwi-text-black" 
                        >
                          <span>{{ch.title}}</span>
                        </a>
                        <span v-if="j === 0">,</span>
                      </template>

                    </div>
                  </div>
                  </template>
                  <a 
                    :href="item.link" 
                    class="d-flex flex-row flex-wrap align-center text-decoration-none kiwi-text-black" 
                    :class="i > 0 ? 'mt-4' : ''"
                    v-else-if="!item.children"
                  >
                    <div class="d-flex flex-row align-center">
                      <v-icon class="mdi mr-2 kiwi-text-primary" :class="item.icon"></v-icon>
                    <span>{{item.title}}</span>
                    </div>
                  </a>
                </template>
                <div
                    class="d-flex flex-row flex-wrap align-start text-decoration-none kiwi-text-black mt-3" 
                  >
                    <v-icon class="mdi mdi-map-marker-outline mr-2 kiwi-text-primary"></v-icon>
                    <div style="flex: 1;"><p>Dhaka, Bangladesh</p></div>
                  </div>
              </div>
            </article>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <article class="d-flex flex-column">
              <img src="/assets/US.png" alt="US FLag" class="mb-3" style="width: 28px;">
              <div class="d-flex flex-column align-start justify-start py-2">
                <div class="kiwi-fs-15 mb-4">
                  <b class="font-weight-semi-bold kiwi-fs-15">U.S. Office</b> 
                  <div
                    class="d-flex flex-row flex-wrap align-start text-decoration-none kiwi-text-black mt-3" 
                  >
                    <v-icon class="mdi mdi-account mr-2 kiwi-text-primary"></v-icon>
                    <div style="flex: 1;"><p class="kiwi-fs-14">Suraj Samy (Representative)</p></div>
                  </div>
                  
                </div>
                <template v-for="(item, i) in contactUs" :key="i">
                  <template v-if="item.children">
                  <div
                    class="d-flex flex-row flex-wrap align-start text-decoration-none kiwi-text-black" 
                  >
                    <v-icon class="mdi mr-2 kiwi-text-primary" :class="item.icon"></v-icon>
                    <div style="flex: 1;">
                      <template
                        v-for="(ch, j) in item.children"
                        :key="j"
                      >
                        <a
                          :href="ch.link" 
                          :class="j > 0 ? 'ml-2' : ''"
                          class="flex-grow-1 w-100 text-decoration-none kiwi-text-black" 
                        >
                          <span>{{ch.title}}</span>
                        </a>
                        <span v-if="j === 0">,</span>
                      </template>

                    </div>
                  </div>
                  </template>
                  <a 
                    :href="item.link" 
                    class="d-flex flex-row flex-wrap align-center text-decoration-none kiwi-text-black" 
                    :class="i > 0 ? 'mt-4' : ''"
                    v-else-if="!item.children"
                  >
                    <v-icon class="mdi mr-2 kiwi-text-primary" :class="item.icon"></v-icon>
                    <span>{{item.title}}</span>
                  </a>
                </template>
                <div
                    class="d-flex flex-row flex-wrap align-start text-decoration-none kiwi-text-black mt-3" 
                  >
                    <v-icon class="mdi mdi-map-marker-outline mr-2 kiwi-text-primary"></v-icon>
                    <div style="flex: 1;"><p>6143 Main St, Mt Jackson, VA 22842, USA</p></div>
                </div>
              </div>
            </article>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <article class="d-flex flex-column">
              <img src="/assets/India.png" alt="India FLag" class="mb-3" style="width: 28px;">
              <div class="d-flex flex-column align-start justify-start py-2">
                <div class="kiwi-fs-15 mb-4">
                  <b class="font-weight-semi-bold kiwi-fs-15">India Office</b> 
                  <div
                    class="d-flex flex-row flex-wrap align-start text-decoration-none kiwi-text-black mt-3" 
                  >
                    <v-icon class="mdi mdi-account mr-2 kiwi-text-primary"></v-icon>
                    <div style="flex: 1;" ><p class="kiwi-fs-14">Shubham Karn (Representative)</p></div>
                  </div>
                  
                </div>
                <template v-for="(item, i) in contactIndia" :key="i">
                  <template v-if="item.children">
                  <div
                    class="d-flex flex-row flex-wrap align-start text-decoration-none kiwi-text-black" 
                  >
                    <v-icon class="mdi mr-2 kiwi-text-primary" :class="item.icon"></v-icon>
                    <div style="flex: 1;">
                      <template
                        v-for="(ch, j) in item.children"
                        :key="j"
                      >
                        <a
                          :href="ch.link" 
                          :class="j > 0 ? 'ml-2' : ''"
                          class="flex-grow-1 w-100 text-decoration-none kiwi-text-black" 
                        >
                          <span>{{ch.title}}</span>
                        </a>
                        <span v-if="j === 0">,</span>
                      </template>

                    </div>
                  </div>
                  </template>
                  <a 
                    :href="item.link" 
                    class="d-flex flex-row flex-wrap align-center text-decoration-none kiwi-text-black" 
                    :class="i > 0 ? 'mt-4' : ''"
                    v-else-if="!item.children"
                  >
                    <v-icon class="mdi mr-2 kiwi-text-primary" :class="item.icon"></v-icon>
                    <span>{{item.title}}</span>
                  </a>
                </template>
                <div
                    class="d-flex flex-row flex-wrap align-start text-decoration-none kiwi-text-black mt-3" 
                  >
                    <v-icon class="mdi mdi-map-marker-outline mr-2 kiwi-text-primary"></v-icon>
                    <div style="flex: 1;"><p>New Delhi, India</p></div>
                  </div>
              </div>
            </article>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <v-divider></v-divider>
    <section class="kiwi-footer-bottom">
      <v-container>
        <v-row>
          <v-col cols="12" md="6">
            Copyright &copy; 2024 by Susankya Global. All Rights Reserved.
          </v-col>
          <v-col cols="12" md="6" class="text-md-right">
            <template v-for="(link, LI) in copyrightLink" :key="LI">
              <router-link :to="link.link" :class="LI > 0 ? 'ml-2 ml-md-4' : ''" style="text-decoration: none; color: var(--color-black);">{{link.title}}</router-link>
            </template>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </footer>
</template>

<script setup>
  import {ref, computed} from 'vue'
  import Intercom from '@intercom/messenger-js-sdk';
  import { useInstituteStore } from '@/stores/institute/institute-store'
  import { storeToRefs } from 'pinia';
  const store = useInstituteStore();
  const {configData} = storeToRefs(store)
  const enabled_features = computed(() => {
    return configData.value.institute.enabled_features
  })
    setTimeout(() => {
      if(enabled_features.value.includes('intercom-chat')) {
          Intercom({
            app_id: 'wgokdvuc',
          });
      }
    }, 500)
  
  const company = ref([]);
  company.value = [
    {
      title: 'Products',
      link: '/product'
    },
    {
      title: 'Features',
      link: '/feature'
    },
  ]
  
  const support = ref([]);
  support.value = [
    {
      title: 'Blog',
      link: '/blog'
    },
    {
      title: 'Pricing',
      link: '/pricing'
    },
  ]
  
  const copyrightLink = ref([]);
  copyrightLink.value = [
    {
      title: 'Privacy Policy',
      link: '/privacy'
    },
    {
      title: 'Terms of Service',
      link: '/terms-and-conditions'
    },
  ]

  const contact = ref({});
  contact.value = [
    {
      title: '+977 9803264626',
      icon: 'mdi-phone',
      link: 'tel:+9779803264626',
    },
    {
      title: 'info@susankya.com',
      icon: 'mdi-email',
      link: 'mailto:info@susankya.com'
    },
  ]

  const contactBangladesh = ref({});
  contactBangladesh.value = [
    {
      title: '(+880)-1838602973',
      icon: 'mdi-phone',
      link: 'tel:+8801838602973',
    },
    {
      title: 'mirnmoychakroborty@susankya.com',
      icon: 'mdi-email',
      link: 'mailto:mirnmoychakroborty@susankya.com'
    },
  ]

  const contactUs = ref({});
  contactUs.value = [
    {
      title: '+1-804-956-1349',
      icon: 'mdi-phone',
      link: 'tel:+18049561349',
    },
    {
      title: 'us@susankya.com',
      icon: 'mdi-email',
      link: 'mailto:us@susankya.com'
    },
  ]

  const contactIndia = ref({});
  contactIndia.value = [
    {
      title: '(+91) 8368646463',
      icon: 'mdi-phone',
      link: 'tel:+918368646463',
    },
    {
      title: 'india@susankya.com',
      icon: 'mdi-email',
      link: 'mailto:india@susankya.com'
    },
  ]
</script>

<style lang="scss" >
.v-list-item--nav .v-list-item-title.kiwi-fs-16 {
  font-size: 16px !important;
}

.kiwi-footer {
  .v-list-item::after, .v-list-item__overlay {
    display: none;
  }

  .social-icon-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    background-color: #F0F1F2;
    border-radius: 100%;
  }
}
.kiwi-footer-middle {
  font-size: 14px;
}
</style>