<template>
  <div>
        <section class="individual-evaluation-processing py-4" v-if="isSubmitted && pendingAnswers.some(item => !item.is_evaluation_complete)">
          <div class="my-2">
            <div class="d-flex flex-wrap">
              <div class="user-img mx-2 d-flex align-start justify-center overflow-hidden">
                <img v-if="pendingAnswers[0]?.user_img && (!isAgent && !isInstitute)" src="/assets/PLaceholder.svg" alt="" style="width: 100%; height: 100%;">
                <img :src="institute.invertedFavicon" v-else class="logo" :class="'cursor-pointer'" alt="kiwi logo" style="width: 100%; height: 100%;">
              </div>
              <div class="content flex-grow-1 mx-2 position-relative">
                <div class="flex-grow-1">
                  <div class="d-flex align-center w-100">
                    <div class="name d-flex flex-column mr-2 w-100" style="min-width: 150px; max-width: 200px;">
                        <span class="kiw-fs-16 font-weight-semi-bold kiwi-text-black-3">{{pendingAnswers[0]?.user === user?.id ? user?.user_full_name : ''}}</span>
                        <span class="date kiwi-fs-14 kiwi-text-light-black-2">{{moment(pendingAnswers[0]?.created).format('MM-DD-YYYY, h:mm A')}}</span>
                    </div>
                    <v-divider class="ml-2 w-100" />
                  </div>
                  <div class="py-4" :key="pendingAnswers[0].id">
                      <span class="kiwi-fs-16 font-weight-medium kiwi-text-black-3">Your Answer:</span>
                      <template v-if="pendingAnswers[0]?.question_data?.topic_slug === 'ra'">
                          <Player :source="pendingAnswers[0]?.answer_audio_file" />
                      </template>
                      <template v-if="pendingAnswers[0]?.question_data?.topic_slug === 'rs'">
                          <Player :source="pendingAnswers[0]?.answer_audio_file" />
                      </template>
                      <template v-if="pendingAnswers[0]?.question_data?.topic_slug === 'di'">
                          <Player :source="pendingAnswers[0]?.answer_audio_file" />
                      </template>
                      <template v-if="pendingAnswers[0]?.question_data?.topic_slug === 'rl'">
                          <Player :source="pendingAnswers[0]?.answer_audio_file" />
                      </template>
                      <template v-if="pendingAnswers[0]?.question_data?.topic_slug === 'asq'">
                          <Player  :source="pendingAnswers[0]?.answer_audio_file" />
                      </template>
                      <template v-if="pendingAnswers[0]?.question_data?.topic_slug === 'swt'">
                          <p class="kiwi-fs-16 kiwi-text-light-black-2">{{pendingAnswers[0]?.answer_text}}</p>
                      </template>
                      <template v-if="pendingAnswers[0]?.question_data?.topic_slug === 'we'">
                          <p class="kiwi-fs-16 kiwi-text-light-black-2">{{pendingAnswers[0]?.answer_text}}</p>
                      </template>
                      <template v-if="pendingAnswers[0]?.question_data?.topic_slug === 'fib-rw'">
                        {{ pendingAnswers[0]?.answer_list.length > 0
                        ? pendingAnswers[0].answer_list
                          .map(item => item.trim() === '' ? '____' : item)
                          .join(', ')
                        : '_____' }}
                      </template>
                      <template v-if="pendingAnswers[0]?.question_data?.topic_slug === 'mcm-r'">
                        <p class="kiwi-fs-16 kiwi-text-light-black-2">{{ findIndices(pendingAnswers[0].marks_obtained_json , pendingAnswers[0].answer_list ).join(', ')}}</p>
                      </template>
                      <template v-if="pendingAnswers[0]?.question_data?.topic_slug === 'ro'">
                        <AReorder :charType="'num'" :options="pendingAnswers[0].question_data?.options || []" :correct="pendingAnswers[0].correct_answer_list || []" :selected="pendingAnswers[0].answer_list || []" :render-type="'answerList'" />
                      </template>
                      <template v-if="pendingAnswers[0]?.question_data?.topic_slug === 'fib-r'">
                        <p class="kiwi-fs-16 kiwi-text-light-black-2">
                          {{ pendingAnswers[0]?.answer_list.length > 0
                          ? pendingAnswers[0].answer_list
                            .map(item => item.trim() === '' ? '____' : item)
                            .join(', ')
                          : '_____' }}
                        </p>
                      </template>
                      <template v-if="pendingAnswers[0]?.question_data?.topic_slug === 'mcs-r'">
                          <p class="kiwi-fs-16 kiwi-text-light-black-2">{{pendingAnswers[0]?.answer_text}}</p>
                      </template>
                    <template v-if="pendingAnswers[0]?.question_data?.topic_slug === 'mcq'">
                      {{findSelected(pendingAnswers[0].marks_obtained_json)}}
                    </template>
                      <template v-if="pendingAnswers[0]?.question_data?.topic_slug === 'sst'">
                          <p class="kiwi-fs-16 kiwi-text-light-black-2">{{pendingAnswers[0]?.answer_text}}</p>
                      </template>
                      <template v-if="pendingAnswers[0]?.question_data?.topic_slug === 'msc-l'">
                          <p class="kiwi-fs-16 kiwi-text-light-black-2">{{pendingAnswers[0]?.answer_text}}</p>
                      </template>
                      <template v-if="pendingAnswers[0]?.question_data?.topic_slug === 'fib-l'">
                          <p class="kiwi-fs-16 kiwi-text-light-black-2"> {{ pendingAnswers[0]?.answer_list.length > 0
                            ? pendingAnswers[0].answer_list
                              .map(item => item.trim() === '' ? '____' : item)
                              .join(', ')
                            : '_____' }}</p>
                      </template>
                      <template v-if="pendingAnswers[0]?.question_data?.topic_slug === 'hcs'">
                        {{findSelected(pendingAnswers[0].marks_obtained_json)}}
                      </template>
                      <template v-if="pendingAnswers[0]?.question_data?.topic_slug === 'mcs-l'">
                        {{findSelected(pendingAnswers[0].marks_obtained_json)}}
                      </template>
                      <template v-if="pendingAnswers[0]?.question_data?.topic_slug === 'smw'">
                        {{findSelected(pendingAnswers[0].marks_obtained_json)}}
                      </template>
                      <template v-if="pendingAnswers[0]?.question_data?.topic_slug === 'hiw'">
                        {{findHighlightedAnswer(pendingAnswers[0].answer_list , pendingAnswers[0].question_data?.question_text)}}
                      </template>
                      <template v-if="pendingAnswers[0]?.question_data?.topic_slug === 'wfd'">
                          <p class="kiwi-fs-16 kiwi-text-light-black-2">{{pendingAnswers[0]?.answer_text}}</p>
                      </template>
                    <template v-if="pendingAnswers[0]?.question_data?.topic_slug === 'mcm-l'">
                      <p class="kiwi-fs-16 kiwi-text-light-black-2">{{ findIndices(pendingAnswers[0].marks_obtained_json , pendingAnswers[0].answer_list ).join(', ') }}</p>
                    </template>
                  </div>
                </div>
                <div class="position-absolute kiwi-bg-light-grey-5" style="right: 0; top: 0px;">
                    <v-btn class="kiwi-bg-primary kiwi-text-white text-none" variant="flat" @click="watchCurrentAnswer(pendingAnswers[0].id)" v-if="!isScoreEvaluating">Click to Score</v-btn>
                    <v-btn class="kiwi-bg-primary kiwi-text-white text-none" variant="flat" v-else>Evaluating Score...</v-btn>
                </div>
              </div>
            </div>
          </div>
        </section>
    <v-sheet
        variant="flat"
        class="kiwi-bg-white individual-question-evaluation kiwi-radius-8 overflow-hidden"
        v-if="prevAnswer"
    >
        <article class="pa-4 kiwi-fs-20 font-weight-bold d-flex align-center">
            <img src="/assets/bulb.svg" class="mr-2" alt="bulb vector">
            <span class="upgrade-gradient-text">Submissions and Results</span>
        </article>
        <v-divider class="" />
        <section class="kiwi-bg-light-grey-3 px-4" v-if="isSubmitted && !['hiw' , 'fib-rw','mcm-r','ro','fib-r','mcq','mcm-l','fib-l','hcs','mcs-l','smw','wfd'].includes(slug)">
            <div class="py-4">
                <span class="kiwi-fs-16 font-weight-medium kiwi-text-black-3">Sample Answer:</span>
                <template v-if="prevAnswerSlug === 'ra'">
                    <Player :source="prevAnswer?.question_data?.answer_audio" />
                </template>
                <template v-if="prevAnswerSlug === 'rs'">
                  {{prevAnswer?.question_data?.answer_text}}
                </template>
                <template v-if="prevAnswerSlug === 'di'">
                    {{prevAnswer?.question_data?.answer_text}}
                </template>
                <template v-if="prevAnswerSlug === 'rl'">
                    {{prevAnswer?.question_data?.answer_text}}
                </template>
                <template v-if="prevAnswerSlug === 'asq'">
                  {{prevAnswer?.question_data?.answer_list.join(',')}}
                </template>
                <template v-if="prevAnswerSlug === 'swt'">
                    <p class="kiwi-fs-16 kiwi-text-light-black-2">{{prevAnswer?.question_data?.answer_text}}</p>
                </template>
                <template v-if="prevAnswerSlug === 'we'">
                    <p class="kiwi-fs-16 kiwi-text-light-black-2">{{prevAnswer?.question_data?.answer_text}}</p>
                </template>
                <template v-if="prevAnswerSlug === 'fib-rw'">
                    <p class="kiwi-fs-16 kiwi-text-light-black-2">{{prevAnswer?.question_data?.answer_text}}</p>
                </template>
                <template v-if="prevAnswerSlug === 'mcm-r'">
                    <p class="kiwi-fs-16 kiwi-text-light-black-2">{{prevAnswer?.question_data?.answer_text}}</p>
                </template>
                <template v-if="prevAnswerSlug === 'ro'">
                  <AReorder :charType="'num'" :options="answer.question_data?.options || []" :correct="answer.correct_answer_list || []" :selected="answer.answer_list || []" :render-type="'answerList'" />
                </template>
                <template v-if="prevAnswerSlug === 'fib-r'">
                    <p class="kiwi-fs-16 kiwi-text-light-black-2">{{prevAnswer?.question_data?.answer_text}}</p>
                </template>
                <template v-if="prevAnswerSlug === 'mcs-r'">
                    <p class="kiwi-fs-16 kiwi-text-light-black-2">{{prevAnswer?.question_data?.answer_text}}</p>
                </template>
                <template v-if="prevAnswerSlug === 'sst'">
                    <p class="kiwi-fs-16 kiwi-text-light-black-2">{{prevAnswer?.question_data?.answer_text}}</p>
                </template>
                <template v-if="prevAnswerSlug === 'msc-l'">
                    <p class="kiwi-fs-16 kiwi-text-light-black-2">{{prevAnswer?.question_data?.answer_text}}</p>
                </template>
                <template v-if="prevAnswerSlug === 'fib-l'">
                    <p class="kiwi-fs-16 kiwi-text-light-black-2">{{prevAnswer?.question_data?.answer_text}}</p>
                </template>
                <template v-if="prevAnswerSlug === 'hcs'">
                    <p class="kiwi-fs-16 kiwi-text-light-black-2">{{prevAnswer?.question_data?.answer_text}}</p>
                </template>
                <template v-if="prevAnswerSlug === 'mcs-l'">
                    <p class="kiwi-fs-16 kiwi-text-light-black-2">{{prevAnswer?.question_data?.answer_text}}</p>
                </template>
                <template v-if="prevAnswerSlug === 'smw'">
                    <p class="kiwi-fs-16 kiwi-text-light-black-2">{{prevAnswer?.question_data?.answer_text}}</p>
                </template>
                <template v-if="prevAnswerSlug === 'hiw'">
                    <p class="kiwi-fs-16 kiwi-text-light-black-2">{{prevAnswer?.question_data?.answer_text}}</p>
                </template>
                <template v-if="prevAnswerSlug === 'wfd'">
                    <p class="kiwi-fs-16 kiwi-text-light-black-2">{{prevAnswer?.question_data?.answer_text}}</p>
                </template>
            </div>
        </section>
        <v-divider />
        <section class="answer-list py-2 px-2 kiwi-bg-light-grey-5 border kiwi-border-light-grey-3">
            <div class="answer-container my-2">
                <div class="d-flex flex-wrap" v-for="(answer, index) in previousAnswers" :key="index" v-show="Object.keys(answer)?.length">
                    <div class="user-img mx-2 d-flex align-center justify-center overflow-hidden">
                        <!-- <img v-if="answer?.user_img && (!isAgent && !isInstitute)" :src="answer?.user_img" alt=""> -->
                        <img v-if="answer?.user_img && (!isAgent && !isInstitute)" src="/assets/PLaceholder.svg" alt="" style="width: 100%; height: 100%;">
                        <img :src="institute.invertedFavicon" v-else class="logo" :class="'cursor-pointer'" alt="kiwi logo" style="width: 100%; height: 100%;">
                    </div>
                    <div class="content flex-grow-1 mx-2 position-relative">
                        <div class="flex-grow-1">
                            <div class="d-flex align-center w-100">
                                <div class="name d-flex flex-column mr-2 w-100" style="min-width: 150px; max-width: 200px;">
                                    <span class="kiw-fs-16 font-weight-semi-bold kiwi-text-black-3">{{answer?.user === user?.id ? user?.user_full_name : ''}}</span>
                                    <span class="date kiwi-fs-14 kiwi-text-light-black-2">{{moment(answer?.created).format('MM-DD-YYYY, h:mm A')}}</span>
                                </div>
                                <v-divider class="ml-2 w-100" />
                            </div>
                            <div class="py-4" :key="answer.id">
                                <span class="kiwi-fs-16 font-weight-medium kiwi-text-black-3">Your Answer:</span>
                                <template v-if="answer?.question_data?.topic_slug === 'ra'">
                                    <Player :source="answer?.answer_audio_file" />
                                </template>
                                <template v-if="answer?.question_data?.topic_slug === 'rs'">
                                    <Player :source="answer?.answer_audio_file" />
                                </template>
                                <template v-if="answer?.question_data?.topic_slug === 'di'">
                                    <Player :source="answer?.answer_audio_file" />
                                </template>
                                <template v-if="answer?.question_data?.topic_slug === 'rl'">
                                    <Player :source="answer?.answer_audio_file" />
                                </template>
                                <template v-if="answer?.question_data?.topic_slug === 'asq'">
                                    <Player  :source="answer?.answer_audio_file" />
                                </template>
                                <template v-if="answer?.question_data?.topic_slug === 'swt'">
                                    <p class="kiwi-fs-16 kiwi-text-light-black-2">{{answer?.answer_text}}</p>
                                </template>
                                <template v-if="answer?.question_data?.topic_slug === 'we'">
                                    <p class="kiwi-fs-16 kiwi-text-light-black-2">{{answer?.answer_text}}</p>
                                </template>
                                <template v-if="answer?.question_data?.topic_slug === 'fib-rw'">
                                  {{ answer?.answer_list.length > 0
                                  ? answer.answer_list
                                    .map(item => item.trim() === '' ? '____' : item)
                                    .join(', ')
                                  : '_____' }}
                                </template>
                                <template v-if="answer?.question_data?.topic_slug === 'mcm-r'">
                                  <p class="kiwi-fs-16 kiwi-text-light-black-2">{{ findIndices(answer.marks_obtained_json , answer.answer_list ).join(', ')}}</p>
                                </template>
                                <template v-if="answer?.question_data?.topic_slug === 'ro'">
                                  <AReorder :charType="'num'" :options="answer.question_data?.options || []" :correct="answer.correct_answer_list || []" :selected="answer.answer_list || []" :render-type="'answerList'" />
                                </template>
                                <template v-if="answer?.question_data?.topic_slug === 'fib-r'">
                                  <p class="kiwi-fs-16 kiwi-text-light-black-2">
                                    {{ answer?.answer_list.length > 0
                                    ? answer.answer_list
                                      .map(item => item.trim() === '' ? '____' : item)
                                      .join(', ')
                                    : '_____' }}
                                  </p>
                                </template>
                                <template v-if="answer?.question_data?.topic_slug === 'mcs-r'">
                                    <p class="kiwi-fs-16 kiwi-text-light-black-2">{{answer?.answer_text}}</p>
                                </template>
                              <template v-if="answer?.question_data?.topic_slug === 'mcq'">
                                {{findSelected(answer.marks_obtained_json)}}
                              </template>
                                <template v-if="answer?.question_data?.topic_slug === 'sst'">
                                    <p class="kiwi-fs-16 kiwi-text-light-black-2">{{answer?.answer_text}}</p>
                                </template>
                                <template v-if="answer?.question_data?.topic_slug === 'msc-l'">
                                    <p class="kiwi-fs-16 kiwi-text-light-black-2">{{answer?.answer_text}}</p>
                                </template>
                                <template v-if="answer?.question_data?.topic_slug === 'fib-l'">
                                    <p class="kiwi-fs-16 kiwi-text-light-black-2"> {{ answer?.answer_list.length > 0
                                      ? answer.answer_list
                                        .map(item => item.trim() === '' ? '____' : item)
                                        .join(', ')
                                      : '_____' }}</p>
                                </template>
                                <template v-if="answer?.question_data?.topic_slug === 'hcs'">
                                  {{findSelected(answer.marks_obtained_json)}}
                                </template>
                                <template v-if="answer?.question_data?.topic_slug === 'mcs-l'">
                                  {{findSelected(answer.marks_obtained_json)}}
                                </template>
                                <template v-if="answer?.question_data?.topic_slug === 'smw'">
                                  {{findSelected(answer.marks_obtained_json)}}
                                </template>
                                <template v-if="answer?.question_data?.topic_slug === 'hiw'">
                                  {{findHighlightedAnswer(answer.answer_list , answer.question_data?.question_text)}}
                                </template>
                                <template v-if="answer?.question_data?.topic_slug === 'wfd'">
                                    <p class="kiwi-fs-16 kiwi-text-light-black-2">{{answer?.answer_text}}</p>
                                </template>
                              <template v-if="answer?.question_data?.topic_slug === 'mcm-l'">
                                <p class="kiwi-fs-16 kiwi-text-light-black-2">{{ findIndices(answer.marks_obtained_json , answer.answer_list ).join(', ') }}</p>
                              </template>
                            </div>
                        </div>
                        <div class="position-absolute pl-4 kiwi-bg-light-grey-5" style="right: 0; top: 0px;">
                            <v-chip variant="outlined" @click="viewDetail(answer.id , index)" class="cursor-pointer kiwi-border-lightest-grey-2 font-weight-semi-bold bg-white" size="large">
                                <span class="kiwi-text-black-3 mr-1">Score details:</span> <span class="kiwi-text-marigold">{{ parseFloat(answer?.obtained_marks)+'/'+parseFloat(answer?.full_marks) }}</span>
                            </v-chip>
                        </div>
                    </div>
                </div>
            </div>
          <v-divider class="mb-2" />
          <div class="d-flex justify-center">
            <v-btn
              style="margin: 0 !important;"
              class=" text-none text-uppercase   kiwi-bg-primary kiwi-text-white mt-4"
              @click="loadAnswers"
              v-if="previousAnswerPagination.next"
              :loading="loadMore"
            >
              Load More
            </v-btn>
          </div>
        </section>
        <AnswerScore :content="previousAnswers[scoreIndex]" :index="questionOffset"  v-if="detail" @handleClose="detail = false" />
  </v-sheet>
  </div>
</template>

<script setup>
import {ref, onMounted, computed, watch} from 'vue'
import { useInstituteStore } from '@/stores/institute/institute-store';
import {useConfirmer} from "@/stores/confirmer";
import moment from 'moment';
import {useRoute} from 'vue-router'
import Player from '@/components/Evaluation/MockTests/Answers/APlayer.vue'
import AnswerScore from "@/components/Evaluation/IndividualQuestionTest/AnswerScore.vue";
import { useAuthStore } from '@/stores/auth/authStore'
import { useIndividualQustionsTestStore } from '@/stores/individual-question-test-store'
import { storeToRefs } from 'pinia';
import {useAnswer} from "@/composables/useAnswer";
import AReorder from "@/components/Evaluation/MockTests/Answers/AReorder.vue";

const institute = useInstituteStore();
const {findSelected , findIndices ,findHighlightedAnswer } =useAnswer();
const props = defineProps(['answer', 'slug'])
const detail = ref(false)
const route = useRoute();
const auth = useAuthStore();
const {isStudent, isInstitute, isAgent} = auth;
const { user } = auth;
const scoreIndex = ref(0);
const {topic_id, id} = route.params
const individualStore = useIndividualQustionsTestStore();
const {previousAnswers , previousAnswerPagination , loadMore , isSubmitted ,questionOffset, pendingAnswers} = storeToRefs(individualStore)
const {getScoreDetails , loadMorePreviousAnswers, getPreviousAnswers, getAnswerProcessingStatus, removeFromPendingAnswer } = individualStore
const workers = ref({})
const confirmer = useConfirmer();
const viewDetail = async (answerId , index) => {
  await getScoreDetails(answerId);
  scoreIndex.value = index;
  detail.value = true;
}


const prevAnswer = computed(() => {
  return previousAnswers.value.length ? previousAnswers.value[scoreIndex.value] : null
})
const prevAnswerSlug = computed(() => {
  return previousAnswers.value.length ? previousAnswers.value[scoreIndex.value]?.question_data?.topic_slug : null
})
onMounted(() => {
    individualStore.getPreviousAnswers(topic_id, id);
})
const loadAnswers = () =>{
  loadMorePreviousAnswers();
}
watch(pendingAnswers, (val) => {
  const workerValues = {...workers.value}
  val.forEach(ans => {
    const id = ans.id
    if(!(id in workers.value)) {
      workerValues[id] = {
        status: 'pending',
        id: id,
        interval: null
      }
    }
  });
  workers.value = workerValues
})
const isScoreEvaluating = ref(false);
const watchCurrentAnswer  = async (id) => {
   let interval = null
   isScoreEvaluating.value = true;
   workers.value[id].status = 'running'
   let intervalCount = 0
   interval = setInterval(() => {
      intervalCount += 1;
      if(intervalCount > 20) {
          clearInterval(interval)
          getPreviousAnswers(route.params.topic_id, pendingAnswers.value[0].question_data.id)
          confirmer.openSnackBar({type:'error' , msg:'We could not evaluate your score. Please try again later.'});
          isScoreEvaluating.value = false;
          return;
      }
       getAnswerProcessingStatus(id).then(res => {
           const isCompleted = res.data && res.data?.status === 'completed';
           if(isCompleted) {
               clearInterval(interval)
               workers.value[id].status === 'completed'
               removeFromPendingAnswer(id)
               const ans = res.data?.data
               confirmer.openSnackBar({type:'success' , msg:'Your score evaluation process is successfully completed.'});
               isScoreEvaluating.value = false;
               if(ans) {
                getPreviousAnswers(route.params.topic_id, ans.question_data.id)
               }
           }
       })

  }, 4000)
} 
watch(workers, (activeWorkers) => {
  for(let [id, value] of Object.entries(activeWorkers)) {
    // console.log("workd value", value)
    if(value.status === 'pending') {
      //  watchCurrentAnswer(id)     
    }else if(value.satus === "completed") {
      delete workers.value[id]
    }
  }
}, {immediate: true, deep: true})
</script>

<style lang="scss">
.individual-question-evaluation {
    .answer-container {
        .user-img {
            width: 48px;
            height: 48px;
            border-radius: 100%;
            background-color: #C0C4C2;

            & + .content {
                @media screen and (min-width: 1200px) {
                    max-width: calc(100% - 80px);
                }
            }
        }
    }

    audio {
        margin-bottom: 0 !important;
    }
}

.individual-evaluation-processing {
  border: 1px solid var(--color-light-grey-3);
  background-color: #fff;
  border-radius: 8px;
  padding-bottom: 0 !important;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 25px;

  & > .my-2 {
    margin-bottom: 0 !important;
  }
}
</style>
