import { api, apiWithoutAuth } from "@/plugins/axios";
import axios from 'axios';
import { defineStore } from "pinia";
import { useRouter } from "vue-router";
import { computed } from "vue";
import { ref } from "vue";
import { meta } from "@/router/meta";
const DEFAULT_HOST = ['dev.kiwiread.com', 'localhost', 'kiwiread.com', 'www.kiwiread.com']
export const useInstituteStore = defineStore('instituteStore' , () =>{
    const router = useRouter()
    const fetching = ref(false)
    const configData = ref(localStorage.getItem('institute_data') ? JSON.parse(localStorage.getItem('institute_data')) : null)
    async function getConfigData(){
        try {
            fetching.value = true
            await apiWithoutAuth.get('/institute/get-enabled-features/').then(res => {
                configData.value = res.data
                if(!res.data.institute) {
                    window.location.href = res.data.redirect_to
                }
                localStorage.setItem("institute_data", JSON.stringify(configData.value))
                fetching.value = false
            })
        } catch (error) {
        }
    }
    function initStyles () {
        const institute = configData.value?.institute;
        if(institute) {
            if(institute.name) {
                const routeName = router.currentRoute.value.name
                document.title = meta[routeName] ? meta[routeName].title.replaceAll('[BRAND]', institute.name) : institute.name
                // if(document.querySelector('meta[name="description"]')) { 
                //     document.querySelector('meta[name="description"]').setAttribute('content', `Prepare for PTE & SAT with ${institute.name} – the FREE AI-powered platform for PTE essay writing, reading, mock tests, and more. Boost your score with expert feedback!`)  
                //  }
            }
            if(institute.favicon) {
                var link = document.querySelector("link[rel~='icon']");
                if(link) {
                    link.href = institute.favicon;
                }
            }
            const style = document.createElement('style');
            const primaryColor =  institute.primary_color
            style.innerHTML = `
                :root {
                    --color-primary: ${primaryColor};
                    --color-primary-dark: ${darkenHexColor(primaryColor)};
                    --color-primary-dim-50: ${lightenHexColor(primaryColor, 0.04)};
                    --color-primary-menu: ${lightenHexColor(primaryColor, 0.04)};
                }
            `;
            document.head.appendChild(style);
        }
    }
    async function init () {
        initStyles()
        await getConfigData()
        initStyles()
    }
    const name = computed(() => {
        return configData.value?.institute?.name || 'Kiwiread';
    });
    const logo = computed(() => {
        return configData.value?.institute?.logo || '/assets/kiwi-logo.svg';
    });
    const invertedLogo = computed(() => {
        return configData.value?.institute?.secondary_logo || '/assets/logo-white.svg';
    });
    const primaryColor = computed(() => {
        return `#${configData.value?.institute?.primary_color || '1BA870'}`;
    });
    const favicon = computed(() => {
        return `${configData.value?.institute?.favicon || '/assets/kiwi-favicon.svg'}`;
    });
    const invertedFavicon = computed(() => {
        return `${configData.value?.institute?.secondary_favicon || '/assets/kiwi-favicon.svg'}`;
    });
    const isDefault = computed(() => {
        return DEFAULT_HOST.includes(window.location.hostname) || configData.value?.institute?.is_default ? true : false;
    });
    const $hasFeature = (feature) => {
        const enabled = configData.value?.institute?.enabled_features
        return (enabled || []).includes(feature)
    }
    return {
        configData,
        init,
        isDefault,
        name,
        logo,
        $hasFeature,
        primaryColor,
        favicon,
        invertedLogo,
        invertedFavicon
    }
})
function darkenHexColor(hex, percent = 20) {
    // Remove '#' if present
    hex = hex.replace('#', '');

    // Parse hex into RGB values
    let r = parseInt(hex.substring(0, 2), 16);
    let g = parseInt(hex.substring(2, 4), 16);
    let b = parseInt(hex.substring(4, 6), 16);

    // Darken the color by reducing brightness
    r = Math.floor(r * (1 - percent / 100));
    g = Math.floor(g * (1 - percent / 100));
    b = Math.floor(b * (1 - percent / 100));

    // Ensure values stay within the range [0, 255]
    r = Math.min(Math.max(0, r), 255);
    g = Math.min(Math.max(0, g), 255);
    b = Math.min(Math.max(0, b), 255);

    // Convert RGB back to hex
    const darkHex = '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);

    return darkHex;
}
function lightenHexColor(hex, alpha) {
    // Remove '#' if present
    hex = hex.replace('#', '');

    // Parse hex into RGB values
    let r = parseInt(hex.substring(0, 2), 16);
    let g = parseInt(hex.substring(2, 4), 16);
    let b = parseInt(hex.substring(4, 6), 16);

    // Ensure alpha value is within the range [0, 1]
    alpha = Math.min(Math.max(0, alpha), 1);

    // Convert RGB to RGBA format
    const rgbaColor = `rgba(${r}, ${g}, ${b}, ${alpha})`;

    return rgbaColor;
}

