import { api } from "@/plugins/axios";
import { defineStore } from "pinia";
import {ref, reactive, createCommentVNode} from "vue";

export const useIndividualQustionsTestStore = defineStore("individualQuestion", () => {
  const questionsBasedTests = ref([]);
  const previousAnswers = ref([]);
  const total = ref(0);
  const topics = ref([]);
  const fetchingAnswer = ref({
    tests: false
  })
  const fetching = ref({
    tests: false
  });
  const pagination = reactive({
    pageSize: 10,
    page: 1,
  });
  const nextPagination = ref()
  const individualQuestionPagination = ref({});
  const currentQuestionOffset = ref('')
  const scoreDetail = ref({})
  const previousAnswerPagination = ref()
  const loadMore = ref(false)
  const isSubmitted = ref(false)
  const questionOffset = ref(1);
  const pendingAnswers = ref([])
  function addToPendingAnswers (obj) {
    pendingAnswers.value = [{...obj}, ...pendingAnswers.value]
  }
  function removeFromPendingAnswer (id) {
    pendingAnswers.value = pendingAnswers.value.filter(a => a.id !== id)
  }
  function getIndividualQuestionTest(topicId) {
    fetching.value.tests = true
    let url = `question/all-questions/?limit=${pagination.pageSize}&offset=${(pagination.page * pagination.pageSize) - pagination.pageSize}&topic=${topicId}`;
    api
      .get(url)
      .then((res) => {
        const response = res.data;
        const {results, ...pagination} = response
        total.value = response.count;
        questionsBasedTests.value = results;
        fetching.value.tests = false
      })
      .catch((error) => {});
  }
  async function getIndividualQuestion(topicId, limit, offset , useCurrentQuestionOffset = false) {
    fetching.value.tests = true
    let url = `question/all-questions/?limit=${limit}&offset=${useCurrentQuestionOffset ? currentQuestionOffset.value - 1: offset}&topic=${topicId}`;
    questionOffset.value = currentQuestionOffset.value
   let res =  await api
    .get(url)
    .then((res) => {
      const response = res.data;
      const {results, ...pagination} = response
      total.value = response.count;
      questionsBasedTests.value = results;
      individualQuestionPagination.value = pagination
      fetching.value.tests = false
      isSubmitted.value=false
      return response
    })
    .catch((error) => {});
   return res
  }
  function getPreviousAnswers(topicId, offset) {
    fetchingAnswer.value.tests = true
    let url = `question/all-questions/${offset}/get_previous_answers/?topic=${topicId}`;
    api.get(url)
    .then((res) => {
      const response = res.data;
      const {results, ...pagination} = response
      previousAnswers.value = results;
      fetchingAnswer.value.tests = false
      previousAnswerPagination.value = pagination ;
    })
    .catch((error) => {
      fetchingAnswer.value.tests = false
    })
  }
  async function getAnswerProcessingStatus (id) {
      return api.get(`answer/${id}`)
  }
  function loadMorePreviousAnswers(){
    const nextUrl  = previousAnswerPagination.value.next;
   if(nextUrl){
     loadMore.value = true;
     api.get(nextUrl).then((res)=>{
       const response  = res.data;
       const {results, ...pagination} = response
       previousAnswers.value.push(...results)
       previousAnswerPagination.value = pagination ;
       loadMore.value = false
     })
   }
  }
  function getAllTopics ()
  {
    let url = `question/topic/?fields=children`;
    api
      .get(url)
      .then((res) => {
        topics.value = res.data?.results;
      })
      .catch((error) => {});
  }

  async function getScoreDetails(answerID) {
    let url = `answer/${answerID}/get-sentence-diff`
    await api.get(url)
    .then((res) => {
      scoreDetail.value = res.data;
    }).catch((error)=>{})
  }
  return {
          questionsBasedTests, getIndividualQuestionTest, getIndividualQuestion,
          total, pagination, topics, getAllTopics, fetching , individualQuestionPagination ,
          currentQuestionOffset, previousAnswers, getPreviousAnswers, fetchingAnswer ,
          getScoreDetails , scoreDetail , loadMorePreviousAnswers ,previousAnswerPagination ,loadMore ,
          isSubmitted , questionOffset, pendingAnswers,
          addToPendingAnswers, removeFromPendingAnswer, getAnswerProcessingStatus
  };
});
