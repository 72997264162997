const meta  = {
    home: {
        title: '[BRAND]',
        description: 'Prepare for PTE & SAT with [BRAND] – the FREE AI-powered platform for PTE essay writing, reading, mock tests, and more. Boost your score with expert feedback!',
    },
    blog: {
        title: 'Blog',
        description: 'Explore the [BRAND] Blog for expert tips, PTE & SAT prep guides, essay writing strategies, reading tips, and more.',
    },
    product: {
        title: 'Product',
        description: 'Unlock [BRAND]’s FREE AI-driven SAT & PTE prep. Sharpen your skills, track your progress, and crush the exam with personalized practice!'
    },
    feature: {
        title: 'Feature',
        description: 'Access powerful features for PTE & SAT prep: AI scoring, real-time feedback, practice tests, essay reviews, speaking assessments, and performance analytics.'
    }
}
export {meta};