<template>
  <div
    class="text-left kiwi-bg-white w-100 mx-auto recorder-component kiwi-radius-4 pa-6 my-0 border"
    style="max-width: 400px; border-color: #8e9492"
  >
    <h3>Recorded Answer</h3>
    <template v-if="autoRecord">
      <p class="font-weight-semi-bold mt-2">Current Status:</p>
      <p class="mt-2">
        <template v-if="recordStatus === 'initial'">
          <span v-if="props.playerEnded">{{showInLabel ? 'Recording in' : 'Recording will start after'}}</span>
          <span class="font-weight-semi-bold ml-1">
            <vue-countdown
              v-if="props.playerEnded"
              :time="counterTime"
              @end="onCountdownEnd"
              v-slot="{ seconds }"
              ref="countDownRef"
            >
              {{ seconds }} seconds
            </vue-countdown>
          </span>
        </template>
        <template v-else-if="recordStatus === 'processing'">
          Recording...
        </template>
        <template v-else-if="recordStatus === 'complete'"> Complete </template>
      </p>
    </template>
    <div class="my-4">
      <Bar ref="barRef" :range="barRange" />
    </div>
    <div v-if="!autoRecord" class="d-flex justify-center">
      <v-btn
        class="text-none mr-2 font-weight-semi-bold"
        style="border-color: #8e9492"
        variant="outlined"
        @click="startRecording"
        :disabled="recording"
      >
        Record
      </v-btn>
      <v-btn
        class="text-none mr-2 font-weight-semi-bold"
        style="border-color: #8e9492"
        variant="outlined"
        @click="playback"
      >
        Playback
      </v-btn>
      <v-btn
        @click="stopRecording"
        class="text-none font-weight-semi-bold"
        style="border-color: #8e9492"
        variant="outlined"
      >
        Stop
      </v-btn>
    </div>
    <div v-if="showSkip">
      <v-btn
        class="text-none mr-2"
        style="border-color: #8e9492"
        variant="outlined"
        @click="skipPrepration"
        :disabled="recording || skip"
      >
        Skip Preparation
      </v-btn>
    </div>
  </div>
  <PromptDialog v-bind="micPrompt" v-if="micPrompt" />
</template>

<script setup>
import { ref, watch, computed, nextTick, defineExpose ,onMounted } from "vue";
import {numRound} from './../../utils/utils'
import { useMicrophone } from "@/composables/useMicrophone";
import Bar from "../ProgressBar/Bar.vue";
import VueCountdown from "@chenfengyuan/vue-countdown";
import { useTranscribeAudio } from "@/composables/useTranscribeAudio";
import PromptDialog from "@/components/Prompt/PromptDialog.vue";
const barRef = ref("");
const countDownRef = ref(null);
const {
  toggleRecording,
  recording,
  togglePlayback,
  playing,
  stopPlayer,
  file,
  playBeep,
  microphoneAccess,
  requestMicrophonePermission,
  getAudioDuration
} = useMicrophone();
const { transcript, fluency, averagePause, startTranscription, stopTranscription, startPauseDetection, stopPauseDetection, isSpeaked} =
  useTranscribeAudio();
const recordStatus = ref("initial");
const skip = ref(false);
const props = defineProps({
  isStatusRequired: {
    type: Boolean,
    default: false,
  },
  autoRecord: {
    type: Boolean,
    default: false,
  },
  recordAfter: {
    type: Number,
    default: 100,
  },
  recordFor: {
    type: Number,
    default: 20,
  },
  withPlayer: {
    type: Boolean,
    default: false,
  },
  playerEnded: {
    type: Boolean,
    default: true,
  },
  playBeepBeforeRecord: {
    type: Boolean,
    default: false,
  },
  showSkip: {
    type: Boolean,
    default: false,
  },
  showInLabel:{
    type:Boolean,
    default:false
  }
});
const counterTimeVal = ref(props.recordAfter);
const micPrompt = ref(null);
const emit = defineEmits(["record:complete", "record:meta", "transcript:complete", "fluency:process", "fluency:pause", "recoder:speak"]);
const counterTime = computed(() => {
  return counterTimeVal.value * 1000;
});
const recordTime = computed(() => {
  return props.recordFor * 1000;
});

const barRange = computed(() => {
  return props.recordFor;
});
const recordedDuration = ref(null);
const recordingStartedAt = ref(null);

const onCountdownEnd = () => {
  if (props.playBeepBeforeRecord) {
    playBeep();
    setTimeout(() => {
      startProcessing();
      completeRecording();
    }, 1000);
  } else {
    startProcessing();
    completeRecording();
  }
};

const startProcessing = () => {
  recordStatus.value = "processing";
  startRecording();
};
const completeRecording = () => {
  setTimeout(() => {
    stopRecording();
    recordStatus.value = "complete";
  }, recordTime.value);
};

const startRecording = async () => {
  await nextTick();
  toggleRecording();
  recordingStartedAt.value = Date.now()
  try {
    startTranscription();
  } catch (err) {}
  try {
    startPauseDetection();
  } catch (err) {}
  if (recording.value) {
    barRef.value.updateProgressRange();
  }
  else {
    console.warn('barref is not recognized', barRef.value)
  }
  // recordedDuration.value = null
};
const stopAudioProcess = () => {
  try {
    stopTranscription();
  } catch (err) {}
  try {
    stopPauseDetection();
  } catch (err) {}
  recordedDuration.value = (Date.now() - recordingStartedAt.value) / 1000
}
const stopRecording = () => {
  if (recording.value) {
    stopAudioProcess()
    toggleRecording();
    // barRef.value.clear();
   
    if(barRef.value) {
      barRef.value.clear();
    }
  } else if (playing.value) {
    stopPlayer();
    barRef.value.clear();
  }
};

const stop = async () => {
  stopAudioProcess()
  if (recording.value) {
    await toggleRecording();
    barRef.value && barRef.value.clear();
    recordStatus.value = "complete";
  }
};

const playback = () => {
  togglePlayback();
  barRef.value.updateProgressRange();
};

const skipPrepration = async () => {
  skip.value = true;
  counterTimeVal.value = 2;
  // countDownRef.value.startCountdown(true);
};

watch(playing, (newVal, oldVal) => {
  if (!newVal) {
    barRef.value.clear();
  }
});

async function calculateMeta  (duration) {
  try {
        const words = (transcript.value || '').replace(/[.,!?;:]/g, '').trim().split(/\s+/)
        const meta = {
          audioDuration: recordedDuration.value,
          totalWord: words.length,
          wpm: words.length && recordedDuration.value ? numRound((words.length * 60) / recordedDuration.value)  : 0,
          wps: words.length && recordedDuration.value ? numRound(words.length / recordedDuration.value)  : 0,
        }
        emit("record:meta", meta);
      }catch(err) {}
}
watch(file, async (newVal, oldVal) => {
  if (newVal) {
    emit("record:complete", file.value);
    if(!recordedDuration.value) {
      recordedDuration.value = (Date.now() - recordingStartedAt.value) / 1000
    }
    const duration = await getAudioDuration(file.value)
    if(duration) {
      recordedDuration.value = numRound(duration)
    }
    calculateMeta()
    try {
      stopTranscription();
    } catch (err) {}
  }
});

watch(transcript, (newVal, oldVal) => {
  if (transcript.value) {
    emit("transcript:complete", transcript.value);
    calculateMeta()
  }
});
watch(fluency, () => {
    emit("fluency:process", fluency.value ? parseFloat(fluency.value.toFixed(2)) : 0);
});
watch(averagePause, () => {
    emit("fluency:pause", averagePause.value ? parseFloat(averagePause.value.toFixed(2)) : 0);
});
watch(isSpeaked, () => {
    emit("recoder:speak",  isSpeaked);
});
watch(
  microphoneAccess,
  (newVal, oldVal) => {
    if(newVal && newVal === "prompt") {
      requestMicrophonePermission();
    }
    if (newVal && newVal === "denied") {
      micPrompt.value = {
        title: "Opps!",
        content:
          "Looks like we can't detect a microphone. Please check your settings and ensure your microphone is connected and allowed!",
        actions: [
          {
            text: "Ok",
            type: "fill",
            onClick: () => {
              micPrompt.value = null;
            },
          },
        ],
      };
    }
  },
  { immediate: true, deep: true }
);

defineExpose({
  stop,
});
</script>
